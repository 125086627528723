<template>
    <div>
        <teleport to="body">
            <Transition name="fade" appear>
                <div class="modal"
                     style="display:block;"
                     v-if="showModal"
                     aria-modal="true"
                     role="dialog">
                    <div class="modal-dialog modal-dialog-centered" ref="modalRef" :class="getModalSizeClass"><div class="modal-content">
                        <button type="button"
                                class="btn-close btn"
                                @click="close"
                                :aria-label="translate('general.sr-close')"><span class="icon icon-close" aria-hidden="true"></span></button>

                        <div class="modal-body">
                            <LoadingSpinner
                                :loading="loading"
                            />
                            <div class="alert alert-danger" v-if="error">
                                {{ translate('general.loading-error') }}
                            </div>
                            <div v-show="content">
                                <div v-html="content"></div>
                            </div>
                        </div>

                        <div class="modal-footer" v-if="showModalFooter">
                            <slot></slot>

                            <button
                                class="btn btn-primary"
                                @click="close">{{ translate('general.close') }}
                            </button>
                        </div>
                    </div>
                    </div>
                </div>
            </Transition>
        </teleport>
    </div>
</template>

<script>
import {translate} from '@elements/translations';
import {initInScope} from '@elements/init-modules-in-scope';
import {ref, computed, onMounted, watch, nextTick} from 'vue';
import LoadingSpinner from './LoadingSpinner.vue';
import {useOnClickOutside} from "js/scripts/composables/click-outside";
import * as lazyImg from '@elements/lazy-img';

export default {
    name: "DynamicModal",
    components: {
        LoadingSpinner
    },
    props: {
        title: {
            type: String,
            required: false
        },
        btnTitle: {
            type: String,
            required: false,
            default: "button"
        },
        btnClassName: {
            type: String,
            required: false,
            default: "btn btn-primary"
        },
        method: {
            type: String,
            default: 'POST',
            required: false
        },
        additionalParams: {
            type: Object,
            required: false
        },
        size: {
            type: String,
            required: false
        },
        showModalFooter: {
            type: Boolean,
            required: false,
            default: true
        },
        showModal: {
            type: Boolean,
            required: true,
            default: false
        },
        content: {
            required: false,
            default: ''
        },
        loading: true,
        error: false
    },
    setup(props, {emit}) {
        const modalRef = ref(null);

        useOnClickOutside(modalRef, () => {
            emit('toggle', false);
            document.body.classList.remove("body--modal-open");
        });

        const getModalSizeClass = computed(() => {
            return props.size ? "modal-" + props.size : "";
        });

        const close = () => {
            emit('toggle', false);
            document.body.classList.remove("body--modal-open");
        }

        onMounted(() => {
            nextTick(lazyImg.init());
        });

        watch(() => props.showModal, (before, after) => {
            if(after === false){
                setTimeout(() => {
                    initInScope(modalRef.value);
                }, 300);
            }
        });
        watch(() => props.content, () => {
            setTimeout(() => {
                initInScope(modalRef.value);
            }, 300);
        });

        return {
            modalRef,
            getModalSizeClass,
            close,
            translate
        };
    },
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
    transform: translateY(5%);
    opacity: 0;
}
</style>

