<template>
    <div class="loading-overlay" v-if="loading">
        <div class="loading-spinner" :aria-label="translate('general.loading')">
            <div class="loading-spinner__item loading-spinner__item--1"></div>
            <div class="loading-spinner__item loading-spinner__item--2"></div>
            <div class="loading-spinner__item loading-spinner__item--3"></div>
        </div>
    </div>
</template>

<script>
import {translate} from '@elements/translations';

export default {
    name: 'LoadingSpinner',
    props: {
        loading: {
            type: Boolean,
            required: true,
        }
    },
    setup() {
        return {translate};
    }
}
</script>
